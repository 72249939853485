import { Grid, Typography } from '@material-ui/core'
import * as React from 'react'

import { FormLabel } from '..'
import useStyles from './styles'


const FormTextInput = ( {
                          children,
                          label,
                          required,
                          textArea,
                          helper,
                          name
                        } ) => {
  const { root, textFieldWrapper, helperClass } = useStyles( {
    textArea
  } )
  return (
      <Grid container className={ root }>
        <Grid item classes={ { root: textFieldWrapper } }>
          { label && <FormLabel { ...{ label, required, name } } /> }
          { children }
        </Grid>
        { helper && (
            <Typography
                className={ helperClass }
                variant="body2"
                align="right"
                component="span"
            >
              { helper }
            </Typography>
        ) }
      </Grid>
  )
}

export default FormTextInput
