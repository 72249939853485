import { Typography } from '@material-ui/core'
import * as React from 'react'
import useStyles from './styles'


const FormLabel = ( { label, required, name } ) => {
  const classes = useStyles()
  return (
      <label htmlFor={ name } className={ classes.label }>
        { label }
        { required && (
            <Typography
                variant="body2"
                component="strong"
                className={ classes.required }
            >
              *
            </Typography>
        ) }
      </label>
  )
}

export default FormLabel
