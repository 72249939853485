import * as React from "react"
import useStyles from "./styles"
import { Typography } from '@material-ui/core'

const FormErrorMessage = ({ errorMessage }) => {
  const { error, errorRoot } = useStyles()
  return (
    <div className={ errorRoot }>
      { errorMessage && (
        <Typography variant="body2" className={ error }>
          { errorMessage }
        </Typography>
      ) }
    </div>
  )
}

export default FormErrorMessage
