import { makeStyles } from '@material-ui/core'


const MAX_WIDTH = 300

export default makeStyles( ( theme ) => ({
  root: {
    [theme.breakpoints.up( 'md' )]: {}
  },

  formWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: ( { centeredForm } ) => centeredForm && 'center'
  },

  formContainer: {
    flexDirection: 'column',
    maxWidth: ( { wideForm } ) => !wideForm && MAX_WIDTH,
    margin: theme.spacing( 1, 0 )
  },

  submitWrapper: {
    maxWidth: ( { wideForm } ) => !wideForm && MAX_WIDTH,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing( 3 )
  }
}) )
