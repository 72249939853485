export const validationStore = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  password: /^(\S){6,50}$/,
  resetToken: /^([A-Z0-9_-]){5,5}$/,
  user: /^(?![0-9._])(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_]+$/,
  nip: /^([0-9_-]){4,4}$/,
  onlyText: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
  onlyNumbers: /^[0-9]{0,10}$/,
  onlyNumbersLong: /^[0-9]{0,20}$/,
  oneNumber: /^[0-9]{1,1}$/,
  phone: /^[0-9]{10,10}$/,
  nick: /^[a-zA-Z ]{0,10}$/,
  mobileID: /^[A-Z0-9]([\w -]*[A-Z0-9])?$/,
  cp: /^[0-9]{5,5}$/,
  rfc: /^([A-ZÑ&]{3,4})(?:- )?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))(?:- )?(([A-Z\d]{2})([A\d]))?$/,
  curp: /[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/
}

