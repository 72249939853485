import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  errorRoot: {
    height: 20
  },
  error: {
    color: theme.palette.status.danger,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'right'
  }
}) )
