// noinspection JSUnfilteredForInLoop

import axios from "axios"
import * as React from "react"
import { useState } from "react"
import { SectionContainer, SectionTitle } from "../../_layout"
import ContactForm from "../ContactForm/ContactForm"

const FORMSPREE_URL = "https://formspree.io/f/moqreoja"

const Contact = () => {
  const [contactSuccess, setContactSuccess] = useState(null)

  const handleSendToFormSpree = async (data) => {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    try {
      const res = await axios.post(FORMSPREE_URL, formData)
      if (res.status === 200) {
        setContactSuccess(true)
      }
    } catch (err) {
      setContactSuccess(false)
    }
  }

  return (
    <SectionContainer sectionID="contact">
      <SectionTitle title="Contáctanos" />
      <ContactForm
        onFormSubmit={ handleSendToFormSpree }
        stateOptions={ {
          state: contactSuccess,
          goBackAction: () => setContactSuccess(null),
          submitSuccessMessage: "Nos pondremos en contacto muy pronto.",
          submitErrorMessage:
            "Error al enviar la forma de contacto. Revisa que la información sea correcta."
        } }
      />
    </SectionContainer>
  )
}

export default Contact
