import { Box, Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import Fade from 'react-reveal/Fade'
import theme from '../../../App.theme'
import { Button } from '../../_common'

import useStyles from './styles'


const FormContainer = ( {
                          children,
                          handleSubmit,
                          isButtonLoading,

                          buttonLabelText = 'Guardar',
                          buttonState = true,
                          centeredForm = false,
                          wideForm = false,
                          stateOptions
                        } ) => {
  const styles = useStyles( { centeredForm, wideForm } )

  const [ height, setHeight ] = useState( 100 )
  const ref = useRef( null )

  useEffect( () => {
    if ( ref.current && ref.current.getBoundingClientRect().height ) {
      setHeight( ref.current.getBoundingClientRect().height )
    }
  }, [] )

  return (
      <Grid container className={ styles.formWrapper }>
        { !stateOptions || stateOptions.state === null ? (
            <Grid ref={ ref } container className={ styles.formContainer }>
              <Fade>{ children }</Fade>

              { handleSubmit && (
                  <Box className={ styles.submitWrapper }>
                    <Button
                        aria={ buttonLabelText }
                        handleClick={ handleSubmit }
                        disabled={ !buttonState }
                        isLoading={ isButtonLoading }
                    >
                      { buttonLabelText }
                    </Button>
                  </Box>
              ) }
            </Grid>
        ) : (
              <Fade>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={ { height } }
                >
                  <Box my={ 2 }>
                    { stateOptions.state === true ? (
                        <AiFillCheckCircle
                            size={ 80 }
                            color={ theme.palette.status.success }
                        />
                    ) : (
                          <AiFillCloseCircle
                              size={ 80 }
                              color={ theme.palette.status.danger }
                          />
                      ) }
                  </Box>
                  <Typography variant="h6" align="center">
                    { stateOptions.state === true
                      ? stateOptions.submitSuccessMessage
                      : stateOptions.submitErrorMessage }
                  </Typography>
                  <Box mt={ 4 }>
                    <Button
                        aria="Entendido"
                        handleClick={ () => stateOptions.goBackAction() }
                    >
                      Entendido
                    </Button>
                  </Box>
                </Grid>
              </Fade>
          ) }
      </Grid>
  )
}

export default FormContainer
