import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  label: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing( 0.5 )
  },
  required: {
    color: theme.palette.status.danger,
    marginLeft: theme.spacing( 0.5 ),
    height: '100%'
  }
}) )
