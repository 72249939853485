import * as React from 'react'

import { FormErrorMessage, FormItemLayout } from '..'
import CssTextField from './styles'


const FormTextInput = React.forwardRef( ( props, ref ) => {
  const {
    label,
    required,
    textArea,
    onChange,
    value,
    errorValue,
    helper,
    type = 'text',
    name,
    placeholder,
    ...textFieldProps
  } = props

  return (
      <>
        <FormItemLayout { ...{ name, helper, required, label, textArea } }>
          <CssTextField
              inputRef={ ref }
              fullWidth
              multiline={ textArea }
              rows={ 4 }
              value={ value === null && type === 'text' ? '-' : value || '' }
              variant="outlined"
              onChange={ onChange }
              autoComplete="off"
              { ...{ type, name, placeholder } }
              { ...textFieldProps }
          />
        </FormItemLayout>
        { <FormErrorMessage errorMessage={ errorValue }/> }
      </>
  )
} )

export default FormTextInput
