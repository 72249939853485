import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  root: {
    borderLeft: theme.spacing( 3 ),
    borderColor: theme.palette.text.lightText,
    display: 'flex',
    flexDirection: 'column'
  },
  textFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  helperClass: {
    width: '100%',
    margin: theme.spacing( 0.5, 1, 0, 0 )
  }
}) )
