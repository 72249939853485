import { validationStore } from '../../../lib/helperFunctions'


const formSchema = () => ({
  initialState: {
    nombre: { value: '', error: '' },
    email: { value: '', error: '' },
    mensaje: { value: '', error: '' }
  },
  validationStateSchema: {
    nombre: { required: true },
    email: {
      required: true,
      validator: {
        regEx: validationStore.email,
        error: 'Favor de introducir un correo válido.'
      }
    },
    mensaje: { required: true }
  }
})

export default formSchema
