import { TextField, withStyles } from '@material-ui/core'
import theme from '../../../App.theme'


export default withStyles( () => ({
  root: {
    backgroundColor: 'red',
    borderRadius: theme.roundnessSmall,

    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing( 0 )
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      fontSize: theme.typography.body2.fontSize,

      '& fieldset': {
        border: `1px ${ theme.palette.text.disabled } solid`,
        borderRadius: theme.roundnessSmall
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing( 1 ),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.roundnessSmall,
      color: ( { disabled } ) =>
          disabled ? theme.palette.text.disabled : theme.palette.text.primary,
      '&.focused': {
        backgroundColor: 'red'
      }
    }
  }
}) )( TextField )
